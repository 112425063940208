.create-testimonial-modal {
    background-color: #F6F8FA;
    border-radius: 8px;
    padding: 25px;
    width: 903px;
    max-width: 90%;
    box-shadow: 0px 0px 40px -5px #010B2333;
@media screen and (max-width:600px) {
    padding:15px;
}
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #01091D;
        }
    }
    .modal-body{
        margin-top:14px;
        background-color: #ffffff;
        border-radius: 6px;
        .inner-content{
            padding:35px 20px 24px;
            .MuiGrid-item{
                padding-top:0;
            }
            // .error-field{
            //     input,
            //     select{
            //         border:1px solid #F13737 !important;
            //         background-color: #FEF6F6 !important;
            //         &::placeholder{
            //             color:#F13737;
            //         }
            //     }
            //     select {
            //         color:#F13737;
            //     }
                
            // }
            .form-textarea{
                margin-bottom: 15px;
            }
            .textarea{
                border:1px solid transparent;
                max-height: 200px;
                overflow: hidden;
                margin-bottom: 0;
                // &.error-field{
                //     border:1px solid #F13737;
                //     background-color: #FEF6F6 !important;
                //     textarea{
                //         &::placeholder{
                //             color:#F13737;
                //         }
                //     }
                    
                // }
                textarea{
                    height: 138px !important;
                    font-weight: 500;
                    font-size: 14px;
                    overflow: auto !important;
                }
                &:focus-within{
                 border:1px solid $color-secondary;
                }
                 
            }
            
            .custom_select{
                margin-bottom: 15px;
                &__title{
                    margin-bottom: 10px;
                }
                select{
                   padding: 13px 15px;
                   border:1px solid transparent;
                   &:focus-within{
                    border:1px solid $color-secondary;
                   }
                   &.error-field{
                   
                        border:1px solid #F13737;
                        background-color: #FEF6F6 !important;
                    .select-placeholder-text{
                        color:#F13737;
                    }
                    
                   }
                }
            }
        }
        .modal-footer{
            border-top: 1px solid rgba(#AAB6BC,0.4);
            padding:24px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            .spinner-circle{
                width: 104px;
            }
            .btn{
                width: 104px;
                box-shadow: none;
                height: auto;
                color: #ffffff;
                font-weight: 500;
            }
        }
    }

}